<!-- 共通コンファーム、共通アラート -->
<template v-if="confirmDialog">
  <div>
    <v-dialog
      v-model="confirmDialog"
      :persistent="settingPersistent"
      :max-width="settingWidth"
    >
      <v-card>
        <v-toolbar dark flat color="primary">
          <v-toolbar-title>{{ confirmTitle }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-3 textArea confirm-detail">{{
          confirmText
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(btn, index) of btns"
            :key="'confirm_btn_' + index"
            :text="btn.text"
            :color="btn.color"
            :type="!btn.type ? btn.type : ''"
            @click="pushBtn(btn.value)"
            :ref="'confirm_btn_' + index"
            >{{ btn.title }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "confirm-component",
  model: {
    prop: "btnNum",
    event: "pushBtn",
  },

  props: {
    dialog: Boolean,
    confirmTitle: {
      type: String,
      default: null,
    },
    confirmText: {
      type: String,
      default: null,
    },
    btns: {
      type: Array,
      default: () => [],
    },
    settingWidth: {
      type: Number,
      default: 400,
    },
    settingPersistent: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    confirmDialog: false,
  }),
  created() {},
  methods: {
    pushBtn(btnNum) {
      this.$emit("pushBtn", btnNum);
    },
  },
  watch: {
    dialog() {
      this.confirmDialog = !this.confirmDialog;
      if (this.confirmDialog) {
        this.$nextTick(() => {
          this.$refs.confirm_btn_0[0].$el.focus();
        });
      }
    },
  },
  components: {},
  computed: {},
};
</script>

<style>
.textArea {
  white-space: pre-line;
}
.confirm-detail {
  max-height: 500px;
  overflow-y: auto;
}
</style>
