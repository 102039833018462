<script>
export default {
  data() {
    return {
      errorContent: {
        field: "",
        code: "",
        message: "",
        itemId: "",
      },
      
      focusTarget: null,
      validationConf: null,
      validateRrefs: []
    }
  },
  methods: {
    convertResponse(res) {
      let result = {message: "", fieldErrors: {original: [], details: []}};

      let tmpRes = null;
      // res.responseの形になっている場合にresponseを取り除く
      if(Object.keys(res).some(key => key == 'response')) {
        tmpRes = res.response;
      } else {
        tmpRes = res;
      }

      // サーバに接続できない場合など、!resultDataになってしまう場合の対処
      if(tmpRes.resultData){
        result.message = tmpRes.resultData.message || tmpRes.resultMessage || "";
        result.fieldErrors.original = tmpRes.resultData.fieldErrors || [];
        result.fieldErrors.details = this.convertErrorList(result.fieldErrors.original) || [];
      } else {
        result.message = tmpRes.resultMessage;
        result.fieldErrors = [];
        result.details = [];
      }

      return result;
    },


    /** サーバーから返却されるエラーオブジェクトをバリデーション用に変換 */
    convertErrorList(originalErrorList, itemId) {
      let newErrorList = [];
      for (let error of originalErrorList) {
        //バリデーション用エラー内容オブジェクト
        let errorContent = {
          field: error.field,
          code: error.code,
          message: error.defaultMessage,
          itemId: itemId
        };

        //fieldが連想配列の場合、必要な値のみを切り出し
        if (error.field.indexOf('.') !== -1) {
          if(error.field.match(/\[(.+)\]/) !== null){
            //APIのResources内にList<Resources>がある場合はこっちに入る。
            errorContent.field = error.field.split('.')[1];
            errorContent.itemId = error.field.match(/\[(.+)\]/)[1];
          } else {
            //APIのResources内にさらにResourcesがある場合はこっちに入る。
            errorContent.field = error.field.split('.')[1];
          }
        }
        newErrorList.push(JSON.parse(JSON.stringify(errorContent)));
      }
      return newErrorList;
    },
    
    /** バリデーション用エラーオブジェクトを画面に反映 */
    openValidationConf(err) {
      this.removeErrorClass();
      this.validateRrefs = [];
      let tempMsgList = [];
      
      for (let errObj of err) {
        //エラー項目のrefsリストを作成
        //let tempRefKey;
        let tempRef;
        if (errObj.itemId != null) {
          tempRef = this.$refs[errObj.field + '_' + errObj.itemId][0];
        } else {
          tempRef = this.$refs[errObj.field];
        }
        //ダイアログに表示するメッセージを取得
        this.validateRrefs.push(tempRef);
        tempMsgList.push( 
          {
            fieldIndex: this.validationFields.indexOf(errObj.field),
            message:errObj.message
          }
        );
      }
      
      //全エラー項目にエラークラスを付与
      for (let ref of this.validateRrefs) {
        this.setErrorClass(ref);
      }

      //メッセージをindexでソートして重複除去
      tempMsgList.sort((a, b) => {
        return a.fieldIndex - b.fieldIndex;
      });
      let msgList = [];
      for (let msg of tempMsgList) {
        msgList.push(msg.message);
      }
      let msgSet = new Set(msgList);
      
      //エラーメッセージダイアログを表示
      let msgText = "";
      for (let msg of msgSet) {
        msgText = msgText + msg + "\n";
      }
      this.validationConf.text = msgText;
      this.validationConf.dialog = true;

      //confirmを閉じた後にフォーカスを合わせる項目に最もtabIndexが小さいものを指定
      let index = 0;
      for (let i in this.validateRrefs) {
        index = Number(this.validateRrefs[index].tabIndex) > Number(this.validateRrefs[i].tabIndex) ? i : index;
      }
      this.focusTarget = this.validateRrefs[index];
    },

    /** 表示されたダイアログをクローズした場合の処理 */
    closeValidationConf() {
      this.validationConf.dialog= false;
      this.$nextTick(()=>{
        this.focusTarget.focus();
      });
    },

    setErrorClass(target) {
      target.hasError = true;
    },

    removeErrorClass() {
      for (let ref of this.validateRrefs) {
        ref.hasError = false;
      }
    },

    /** ArrayBufferからオブジェクトを取り出す */
    convertToStandardResponse(resultData) {
      const resultDataJson = JSON.parse(new TextDecoder().decode(resultData || {}))
      return {response: {resultData: resultDataJson, message: resultDataJson.message}};
    },
  },
};
</script>